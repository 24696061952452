<template>
    <div>
        <div>
            <template v-if="jwt_is_valid">
                <button class="btn btn-primary"     @click="signout" :disabled="processing">Sign OUT</button>
            </template>
            <template v-else>
                <button class="btn btn-primary" @click="signin"  :disabled="processing">Sign IN</button>
                - <input type="text"     v-model="auth.login" placeholder="login">
                - <input type="password" v-model="auth.pass"  placeholder="pass">
            </template>
        </div>

        <hr>

        <div>
            <pre>user: {{ user }}</pre>
            <pre>current_cust_roles: {{ current_cust_roles }}</pre>
        </div>

        <hr>

        <div>
            <button class="btn btn-primary" @click="getCustomers(1)">getCustomers</button>
            <pre>{{ customers }}</pre>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    data() {
        return {
            auth: {
                login: process.env.VUE_APP_TEST_ADMIN_AUTH_LOGIN || '',
                pass: process.env.VUE_APP_TEST_ADMIN_AUTH_PASS || '',
            },

            customers: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'FirstName',
                    order: 'asc'
                },
            },

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            loading: false,
            processing: false,
        }
    },

    methods: {
        signin() {
            this.processing = true

            this.$store.dispatch('LoginEmailPassword', this.auth).then(() => {
                this.processing = false
            }).catch(error => {
                console.error(error)

                this.processing = false
            })
        },
        signout() {
            this.processing = true

            this.$store.dispatch('Logout').then(() => {
                this.processing = false
            }).catch(error => {
                console.error(error)

                this.processing = false
            })
        },



        getCustomers(page) {
            this.loading = true

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': this.filter.sort.field,
                'SearchOptions.SortDesc': this.filter.sort.order === 'desc' ? 1 : 0,
            }

            const search = this.filter.word.trim()

            if (search.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': search,
                    'SearchOptions.PageNumber': search !== this.filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.previous = search
            }

            this.$store.dispatch('getCustomers', { params, filter: this.filter }).then(({apidata}) => {
                this.customers = apidata.Customers.map(customer => ({
                    uuid: customer.UUID,
                    name: this.fullname(customer.FirstName, customer.LastName),
                    email: customer.Email,
                    mobile: customer.MobileNumber,
                    status: customer.Active ? 'Active' : 'Inactive',
                    // registration: customer.CreatedAt ? this.formatDateNano(customer.CreatedAt) : '?',
                    registration: customer.CreatedAt ? {
                        multiline: true,
                        text: this.formatDateNano(customer.CreatedAt)
                    } : '?',
                }))

                const { DisplayPageList, PageNumber, TotalItemCount } = apidata.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                // if (this.$route.params.page != this.pagination.page
                //     && !(
                //         this.$route.params.page === undefined
                //         && this.pagination.page === 1
                //     )
                // ) {
                //     this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                // }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.customers = []

                this.loading = false
            })
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        formatDateNano,
    },

    computed: {
        ...mapGetters([
            'jwt_is_valid',
            'user',
            'current_cust_roles',
        ]),
    },
}
</script>

<style lang="scss">

</style>